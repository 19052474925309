body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  --main-background-color: #f5f5f5;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
