.App {
  word-wrap: break-word !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#active {
  color: #ff9944;
  font-weight: 800;
}

.App-link {
  color: #61dafb;
}

@media screen and (max-width: 600px) {
  .landing_page_homeIcon {
    display: none;
  }

  .home_top_text {
    width: 70vw !important;
  }
}

#root
  > div
  > div.MuiBox-root.css-7cfcti
  > main
  > div
  > div:nth-child(2)
  > div
  > div
  > div
  > div
  > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.css-1idn90j-MuiGrid-root
  > div
  > div
  > div.MuiBox-root.css-187halg
  > div
  > div
  > div:nth-child(2) {
  box-shadow: none !important;
}

#root
  > div
  > div.MuiBox-root.css-7cfcti
  > main
  > div
  > div:nth-child(2)
  > div
  > div
  > div
  > div
  > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.css-15j76c0
  > div
  > div
  > div.MuiBox-root.css-187halg
  > div
  > div
  > div:nth-child(2) {
  box-shadow: none !important;
}

.MuiTableCell-stickyHeader {
  background-color: inherit !important;
}

.dark-table .MuiTable-root {
  background: #525252 !important;
}
